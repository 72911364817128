import React, { useEffect, useContext } from 'react';
import CpmContext from '../context/consent';
import {
  useSearch,
  loadScript,
  loadQrcodeQw,
  loadQrcode,
  loadBaiduhmScript,
  removeBaiduhmScript,
  getDataFromCpm,
  loadQrcodeOffcialByType,
} from '../utils/common';

let collectLoaded = false;
let baiduHmLoaded = false;

const CookieRemindCpm = () => {
  const search = useSearch();
  const { setCurrentConsent } = useContext(CpmContext);

  // 加载统计脚本；先加载埋点脚本，再加载动态二维码
  const getCollect = () => {
    if (!baiduHmLoaded && getDataFromCpm('数据分析')) {
      loadBaiduhmScript();
      baiduHmLoaded = true;
    }
    if (baiduHmLoaded && !getDataFromCpm('数据分析')) {
      baiduHmLoaded = false;
      removeBaiduhmScript();
    }
    if (collectLoaded) {
      return;
    }
    collectLoaded = true;
    loadScript('//cbe.huiju.cool/cbe/collect?tid=1032079986&at=0&h=web', () => {
      const clParamMap = {};
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const params of search.slice(1).split('&')) {
          const [key, value] = params.split('=');
          if (key.indexOf('cl_') === 0 && value) {
            clParamMap[key] = value;
          }
        }
      } catch (err) {
        console.log(err);
      }

      clab_tracker.ready(function () {
        this.push(Object.assign({}, clParamMap));
        this.track('open_page', {});
        loadQrcode();
        loadQrcodeQw('side');
        loadQrcodeQw('footer');
        loadQrcodeQw('contactus');
        loadQrcodeOffcialByType('side_wechat_official');
        loadQrcodeOffcialByType('footer_wechat_official');
        loadQrcodeOffcialByType('blog_article');
        loadQrcodeOffcialByType('contact_us');
      });
      window.clabTracker = clab_tracker;
    });
  };

  useEffect(() => {
    try {
      loadScript('//cpm-api.convertlab.com/sdkApi/cookie/config?x-tenant-id=1', () => {
        if (!window.clab_cpm) {
          return;
        }
        window.clab_cpm.init();
        // window.clab_cpm.addEventListener('ready', (data) => {
        //   console.log('用户状态同步完成: data => ', data);
        //   const cookies = window.clab_cpm.getUserConsent();
        //   if (getDataFromCpm('网站必须')) {
        //     getCollect();
        //   }
        // });
        window.clab_cpm.addEventListener('consentChanged', (data) => {
          // TODO 暂时用这个事件处理初始加载
          // console.log('用户状态发生变化: data => ', data);
          setCurrentConsent(data || []);
          if (getDataFromCpm('网站必须')) {
            getCollect();
          }
        });
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return <div />;
};

export default CookieRemindCpm;

const Data = {
  title: {
    home: '营销云软件-数字化营销运营-会员营销管理系统工具-会员软件-会员营销平台-用户/客户/人群/消费者画像分析系统-标签管理系统-dmp/cdp客户数据平台-上海/北京scrm管理系统-私域流量运营-微信营销平台',
    marketingcloud: '营销云-精准自动化营销-数字/网络营销管理工具-智能营销-网络获客工具',
    cdp: 'dmp-cdp客户数据平台-数字化营销-数据仓库/标签/洞察-数据分析软件',
    dmhub: '营销管理软件-营销自动化系统-营销数字化-营销中台-获客软件系统',
    adhub: '用户/客户/人群画像分析-消费者行为分析-标签管理系统',
    brandretail: '用户人群画像分析软件-用户画像生成器/软件-用户行为画像-数字化转型-行业方案',
    modernretail: 'Convertlab营销实验室|现代零售行业解决方案-全渠道数字化营销服务商',
    fmcg: '营销平台-营销系统-智能营销系统-营销服务-大数据营销-个性化营销-全渠道数字化营销服务商',
    durables: 'Convertlab营销实验室|耐消行业解决方案-全渠道数字化营销服务商',
    financial: 'Convertlab营销实验室|金融行业解决方案-全渠道数字化营销服务商',
    royalty: '营销平台_智能营销系统_营销服务_大数据营销_个性化营销',
    scrm: '北京scrm_上海scrm_scrm管理系统-企业微信营销-微信运营',
    digitalmarketing: '数字化转型_数字化运营_数字化营销',
    campaign: '营销活动_营销自动化系统_营销数字化_智能营销_营销中台_营销软件',
    privatetraffic: '私域流量运营_私域流量营销',
    openhub: 'scrm/dmp/cdp客户数据平台_数据分析软件_数据标签_微信营销平台_用户画像',
    whitepaper: '用户画像分析_用户行为分析_消费者行为分析_获客系统_网络获客',
    ccmeaningful: 'Convertlab营销实验室|CC有料（视频）-全渠道数字化营销服务商',
    about: 'Convertlab营销实验室|关于我们-全渠道数字化营销服务商',
    news: 'Convertlab营销实验室|公司新闻-全渠道数字化营销服务商',
    partner: 'Convertlab营销实验室|合作伙伴-全渠道数字化营销服务商',
    contacts: 'Convertlab营销实验室|联系我们-全渠道数字化营销服务商',
    team: 'Convertlab营销实验室|专家团队-全渠道数字化营销服务商',
    blog: '消费者行为分析_标签管理_客户人群画像_用户标签_用户画像系统', // blog 对应的页面：增长笔记列表页
    industryinsight: '智能营销系统-会员管理系统-数据中台仓库分析软件-消费者/用户行为分析-用户人群画像分析',
    yourtitles: '智能营销系统-大数据营销-个性化营销-用户画像生成器-智能营销主题生成器',
    b2b: 'B2B行业解决方案-荟聚数字营销',
    updateInfo: '营销云产品更新-Convertlab',
    privacy: '隐私政策',
    childrenprivacy: '儿童隐私保护声明',
    cookieprivacy: 'Convertlab Cookie政策',
    summits: '2021第四届中国营销技术峰会-云领时代/智绘未来',
    tahub: 'TA Hub监测分析平台-会员管理系统-会员营销软件-会员管理软件',
    temppage: '营销实验室',
    cpm: 'CPM-CPM同意与偏好管理平台-产品',
    tags: '标签管理系统-数据标签-数据洞察-标签系统-全渠道数字化营销服务商',
    personas: '用户人群画像_用户行为画像_用户画像软件_用户画像生成器',
    blogs: '数据分析软件_数据仓库_数据中台',
  }, // e.g: 'Name | Developer'
  lang: 'zh-cn', // e.g: en, es, fr, jp
  keywords: {
    home: '营销云,数字化营销,数字化运营,私域流量运营,会员管理,会员营销,会员管理系统,会员软件,会员系统,会员管理平台,会员营销系统,会员营销软件,会员营销平台,会员管理工具,会员营销工具,会员营销软件,会员管理软件,标签管理,用户画像,客户画像,人群画像,消费者画像,用户标签,标签系统,标签管理系统,微信运营,scrm系统,scrm管理系统,上海scrm,北京scrm,微信营销平台,营销云软件',
    marketingcloud: '营销云,精准营销,自动化营销,数字营销,网络营销,营销工具,营销管理,营销软件,营销中台,营销自动化,智能营销,营销数字化,网络获客,获客工具,获客软件,获客系统,营销自动化系统,营销活动',
    cdp: 'cdp,dmp,cdp平台,cdp客户数据平台,数字化营销,数字化转型,数字化运营,数据仓库,数据中台,数据分析软件,数据标签,数据洞察',
    dmhub: '营销管理,营销软件,营销自动化系统,营销数字化,营销中台,营销自动化,获客软件,获客系统',
    adhub: '用户画像,消费者行为分析,标签管理,客户画像,人群画像,用户标签,用户画像系统,标签系统,消费者画像,标签管理系统,用户画像分析,用户行为分析',
    brandretail: '用户人群画像、用户画像分析、人群画像、用户画像软件、用户画像生成器、用户行为画像、数字化转型',
    modernretail: '现代零售,零售,零售解决方案,现代零售行业解决方案,零售行业营销方案',
    fmcg: '营销平台、营销系统、智能营销系统、营销服务、大数据营销、个性化营销',
    durables: '耐消,耐消行业,耐消行业解决方案,耐消行业营销方案',
    financial: '金融行业解决方案,金融,金融行业,金融行业营销方案,金融数字化转型',
    royalty: '营销平台,营销系统,智能营销系统,营销服务,大数据营销,个性化营销',
    scrm: '北京scrm,上海scrm,scrm系统,scrm管理系统,微信运营,企业微信营销,微信营销平台,用户运营,scrm',
    digitalmarketing: '数字化转型,数字化运营,数字化营销',
    campaign: '营销活动,营销自动化,营销自动化系统营销数字化,智能营销,营销中台,营销软件营销云软件,精准营销,自动化营销,数字营销,营销,营销工具,营销管理,营销软件',
    privatetraffic: '私域流量运营,私域流量营销,私域流量',
    openhub: 'scrm,dmp,cdp客户数据平台,数据分析软件,数据标签,微信营销平台,用户画像',
    whitepaper: '用户画像分析,用户行为分析,消费者行为分析,获客系统,网络获客',
    about: 'Convertlab,营销实验室,欣兆阳,上海欣兆阳信息科技有限公司',
    news: '公司新闻,Convertlab',
    partner: '合作伙伴,ISV,agency,超级合作伙伴计划,partner',
    contacts: '联系我们,Convertlab',
    team: '营销实验室,一体化营销云,营销专家',
    blog: '消费者行为分析,标签管理,客户人群画像,用户标签,用户画像系统',
    industryinsight: '智能营销系统、会员管理系统、数据中台、数据仓库、数据分析软件、消费者行为分析、用户行为分析、人群画像、用户人群画像、用户画像分析',
    yourtitles: '智能营销系统、大数据营销、个性化营销、用户画像生成器、智能营销主题生成器',
    b2b: 'B2B行业解决方案,荟聚数字营销',
    updateInfo: '营销云产品更新,Convertlab',
    privacy: '隐私政策',
    childrenprivacy: '儿童隐私保护声明',
    cookieprivacy: 'Convertlab Cookie政策',
    summits: '2021第四届中国营销技术峰会,云领时代,智绘未来,云领时代 智绘未来',
    tahub: 'TA Hub监测分析平台-会员管理系统-会员营销软件-会员管理软件',
    temppage: '营销实验室,Convertlab Marketing Cloud',
    cpm: 'CPM,CPM管理平台,CPM同意与偏好管理平台',
    tags: '标签管理系统、数据标签、数据洞察、标签系统',
    personas: '用户人群画像_用户行为画像_用户画像软件_用户画像生成器',
    blogs: '数据分析软件,数据仓库,数据中台',
  },
  description: {
    home: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    marketingcloud: '数字化营销,Convertlab是一体化营销云服务商。',
    cdp: 'cdp客户数据平台,Convertlab是一体化营销云服务商。',
    dmhub: 'MAP营销自动化平台,Convertlab是一体化营销云服务商。',
    adhub: 'Ad Hub智能广告平台,Convertlab是一体化营销云服务商。',
    brandretail: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    modernretail: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    fmcg: 'Convertlab是全渠道数字化营销服务商。',
    durables: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    financial: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    royalty: '会员营销,Convertlab是一体化营销云服务商。',
    scrm: '全渠道SCRM管理平台,Convertlab是一体化营销云服务商。',
    digitalmarketing: '营销运用场景,Convertlab是一体化营销云服务商。',
    campaign: '营销活动管理,Convertlab是一体化营销云服务商。',
    privatetraffic: '私域流量运营,Convertlab是一体化营销云服务商。',
    openhub: 'scrm,Convertlab是一体化营销云服务商。',
    whitepaper: '白皮书下载,Convertlab是一体化营销云服务商。',
    ccmeaningful: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    about: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    news: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    partner: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    contacts: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    team: 'Convertlab是一体化营销云服务商,帮助企业高效运营海量用户,打造品牌特有的个性化用户体验，快速收获业务增长。',
    blog: '消费者行为分析,Convertlab是一体化营销云服务商。',
    industryinsight: 'Convertlab成立于2015年，是一体化智能营销系统服务商。',
    yourtitles: '智能营销主题生成器，你想要的好标题都在这里，一秒钟生成200个创意标题快速生成。',
    b2b: 'Convertlab（营销实验室）是中国营销技术和营销云产品领导者最早在国内推出一体化营销云产品，致力于帮助企业搭建贯通客户生命周期的一体化数字营销平台和配套服务，携手企业拥抱数字营销。',
    updateInfo: 'Convertlab成为全球企业值得信赖的营销伙伴。',
    privacy: '在使用公司的各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用公司的相关产品或服务。一旦您开始使用公司的各项产品或服务，即表示您已充分理解并同意本政策。',
    childrenprivacy: '上海欣兆阳信息科技有限公司（以下简称"Convertlab"或"我们"）致力于保护使用我们产品或服务的儿童的个人信息。',
    cookieprivacy: '上海欣兆阳信息科技有限公司（以下简称“我们”或者“Convertlab”）使用Cookies、像素标签、网络信标、脚本、电子标签设备ID及类似文件或技术（简称“Cookies”），来收集、储存关于您的设备及访问convertlab.com的信息。您可在下文了解更多关于Cookies及其管控方式的信息。',
    summits: '2021.10.14 中国 上海 第四届中国营销技术峰会。',
    tahub: 'TA Hub提供埋点数据采集能力，并针对埋点数据展开分析与洞察。还可与Convertlab其他产品无缝集成，获得一体化全链路数据完整采集并分析的体验，助力广告投放。',
    temppage: '营销实验室，让MarTech (营销技术)加速增长 | 营销人不容错过',
    cpm: 'Convertlab是全渠道数字化营销服务商。',
    tags: 'Convertlab营销云通过建立体系化的多维度标签，将客户属性特征、客户偏好、营销内容、商品品类、沟通反馈等标签化，用智能化自动化的营销流程为数以亿计的客户群体带来美妙的个性化营销体验。',
    personas: '用户画像,Convertlab是一体化营销云服务商。',
    blogs: '资讯中心,Convertlab是一体化营销云服务商。',
  }, // e.g: Welcome to my website
  zcChannels: (key) => {
    let cid = 3;
    if (key === 'home') {
      cid = 3;
    } else if (key === 'products') {
      cid = 5;
    } else if (key === 'solutions') {
      cid = 6;
    } else if (key === 'cases') {
      cid = 7;
    } else if (key === 'aboutus') {
      cid = 8;
    }
    return cid;
  },
};

export default Data;

/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Menu from './Menu';
import Apply from './Apply';
import Footer from './Footer';
import ApplyDialog from './ApplyDialog';
import FixedButtons from './FixedButtons';
import Data from '../data';
import ApplyDialogContext from '../context/applyDialog';
import CurrentContext from '../context/current';
import CpmContext from '../context/consent';
// import CookieRemind from './CookieRemind';
import CookieRemindCpm from './CookieRemindCpm';
import '../style/main.scss';
import { loadQrcodeQw, loadQrcodeOffcialByType } from '../utils/common';

function Layout({
  current,
  subCurrent,
  children,
  type,
  customTitle,
  customKeywords,
  customDescription,
  hideMenu,
  hideApply,
  hideFixedBtns,
  pageId,
}) {
  const { title, lang, description, keywords } = Data;
  const [visible, setVisible] = useState(false);
  const [href, setHref] = useState('');
  const [modalType, setModalType] = useState('apply');
  const [pageFrom, setPageFrom] = useState('');
  const [fileId, setFileId] = useState('');
  const [productId, setProductId] = useState('');
  const [currentConsent, setCurrentConsent] = useState([]);
  const [style, setStyle] = useState({});

  useEffect(() => {
    loadQrcodeQw('side');
    loadQrcodeQw('footer');
    loadQrcodeQw('contactus');
    loadQrcodeQw('joinGroup');
    loadQrcodeQw('joinGroup1');
    loadQrcodeQw('onlineConsulting');
    loadQrcodeOffcialByType('side_wechat_official');
    loadQrcodeOffcialByType('footer_wechat_official');
    loadQrcodeOffcialByType('blog_article');
    loadQrcodeOffcialByType('contact_us');
    const periodOfSilence = [
      new Date('2022-12-06').setHours(0),
      new Date('2022-12-07').setHours(0),
    ];

    const currentTime = Date.now();
    if (currentTime >= periodOfSilence[0] && currentTime <= periodOfSilence[1]) {
      // 默哀期间官网显示黑白样式
      setStyle({
        filter: 'grayscale(1)',
      });
    }
  }, []);

  return (
    <CurrentContext.Provider value={{ current, subCurrent }}>
      <ApplyDialogContext.Provider
        value={{
          visible,
          setVisible,
          href,
          setHref,
          modalType,
          setModalType,
          pageFrom,
          setPageFrom,
          fileId,
          setFileId,
          productId,
          setProductId,
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{customTitle || title[subCurrent] || title.home}</title>
          <html lang={lang} />
          <meta name="keywords" content={customKeywords || keywords[subCurrent] || keywords.home} />
          <meta
            name="description"
            content={customDescription || description[subCurrent] || description.home}
          />
          <meta name="format-detection" content="telephone=yes" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=1.0, user-scalable=yes"
          />
        </Helmet>
        <CpmContext.Provider value={{ currentConsent, setCurrentConsent }}>
          <div style={style}>
            {!hideMenu && type !== 'tool' && type !== 'privacy' && <Menu />}
            <div className="layout-content">{children}</div>
            {type !== 'tool' && type !== 'privacy' && !hideApply && (
              <Apply title="数智赋能每一个岗位" />
            )}
            {type !== 'tool' && type !== 'privacy' && <Footer current={current} />}
            {type !== 'tool' && type !== 'privacy' && !hideFixedBtns && (
              <FixedButtons pageId={pageId} />
            )}
            {type !== 'privacy' && <CookieRemindCpm />}
            {type !== 'privacy' && <ApplyDialog />}
          </div>
        </CpmContext.Provider>
      </ApplyDialogContext.Provider>
    </CurrentContext.Provider>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  current: PropTypes.string,
  subCurrent: PropTypes.string,
  type: PropTypes.string,
  customTitle: PropTypes.string,
  customKeywords: PropTypes.string,
  customDescription: PropTypes.string,
  hideMenu: PropTypes.bool,
  hideApply: PropTypes.bool,
  hideFixedBtns: PropTypes.bool,
  pageId: PropTypes.string,
};

export default Layout;

import React from 'react';
import { Link } from 'gatsby';
import Container from './Container';
import { useSearch } from '../utils/common';

export default function Copyright() {
  const currentYear = new Date().getFullYear();
  const search = useSearch();
  return (
    <div id="copyright" className="copyright">
      <Container>
        <div className="content">
          <div className="item">
            <span>© 2015-{currentYear} 上海欣兆阳信息科技有限公司 All Rights Reserved.</span>
            <a href={`/privacy/${search}`} className="pc-privacy" target="_blank">
              隐私政策
            </a>
            <a href={`/childrenprivacy/${search}`} className="pc-privacy" target="_blank">
              儿童隐私保护声明
            </a>
            <a href={`/cookieprivacy/${search}`} className="pc-privacy" target="_blank">
              Cookie政策
            </a>
          </div>
          <div className="item">
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402005170"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              沪公网安备 31010402005170号
            </a>
            <a
              href="https://beian.miit.gov.cn"
              target="_blank"
              rel="noreferrer noopener nofollow"
              style={{ marginLeft: 3 }}
            >
              沪ICP备15038423号-1
            </a>
          </div>
          <div className="item mobile-privacy">
            <a href={`/privacy/${search}`} target="_blank">
              隐私政策
            </a>
            <a href={`/childrenprivacy/${search}`} target="_blank">
              儿童隐私保护声明
            </a>
            <a href={`/cookieprivacy/${search}`} target="_blank">
              Cookie政策
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

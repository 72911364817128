import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FollowUs from '../images/qrcode/official_side.jpg';
import ApplyDialogContext from '../context/applyDialog';
import track from '../utils/track';
import CurrentContext from '../context/current';
// import CpmRemind from '../images/cpm-remind.gif';
// import { useSearch } from '../utils/common';

// const playEnd = new Date('2021/12/30 15:15:10').valueOf();
// const showCpmRemind = new Date().valueOf() < playEnd;
// const LiveVideoFixedButton = () => {
//   if (!showCpmRemind) {
//     return '';
//   }
//   const search = useSearch();
//   return (
//     <div className="cpm-live-remind">
//       <a href={`/activitycpm/${search}`} target="_blank" rel="noreferrer noopener">
//         <img src={CpmRemind} alt="线上直播" />
//       </a>
//     </div>
//   );
// };

const NavMenu = [
  {
    title: '在线咨询',
    iconId: 'online-consult',
  },
  {
    title: '加入社群',
    iconId: 'weixin-work',
  },
  // {
  //   title: '电话咨询',
  //   // icon: ApplyFor,
  //   iconId: 'phone',
  //   href: 'tel:4008509918',
  // },
  {
    title: '关注微信',
    iconId: 'weixin',
  },
  {
    title: '申请试用',
    iconId: 'applyfor',
  },
  {
    title: '伙伴计划',
    iconId: 'Partners',
  },
];

const WeiXinItem = ({ img, bindingId, fallback }) => {
  return (
    <div className="weixin-qrCode-wrapper">
      <div className="hover-arrow" />
      <div className="hover-content qrCode">
        {bindingId ? (
          <div id={bindingId}>
            <img src={fallback || FollowUs} className="qrCode" alt="QR Code" />
          </div>
        ) : (
          <img src={img} alt="qrCode" />
        )}
      </div>
    </div>
  );
};

WeiXinItem.propTypes = {
  img: PropTypes.string,
  bindingId: PropTypes.string,
  fallback: PropTypes.string,
};

const PhoneCall = () => {
  return (
    <div className="tel-hover-wrapper">
      <div className="hover-arrow" />
      <div className="hover-content link">
        <a href="tel:4008509918">400-850-9918</a>
      </div>
    </div>
  );
};

function FixedButtons() {
  const { setVisible } = useContext(ApplyDialogContext);
  const { current } = useContext(CurrentContext);
  function onBacktoTopClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function onApplyForClick() {
    track({
      name: '申请试用',
      content: '固定右侧栏申请试用按钮',
      current,
    });
    setVisible(true);
  }

  function onBtnClick(id) {
    if (id === 'applyfor') {
      return onApplyForClick;
    }
    if (id === 'Partners') {
      return () => window.open('//b.convertlab.com/p/ce876', '_blank');
    }
    return null;
  }
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  // const isLivePage = props.pageId === 'activitycpm';
  return (
    <>
      {/*{!isLivePage && <LiveVideoFixedButton />}*/}
      {/*<div className="nav-btn-wrapper" style={{ top: !isLivePage && showCpmRemind ? 366 : 280 }}>*/}
      <div className="nav-btn-wrapper" style={{ top: 280 }}>
        {NavMenu.map((nav) => {
          return (
            <div className="nav-btn" key={nav.iconId}>
              <div
                className={`nav-btn-content ${nav.iconId === 'backtotop' ? 'no-text' : ''}`}
                // href={nav.href || '#!'}
                onClick={onBtnClick(nav.iconId)}
              >
                <div className={`nav-btn-icon iconfont font-24 icon${nav.iconId}`} />
                {nav.title !== '回到顶部' && <div>{nav.title}</div>}
                {nav.title === '在线咨询' && (
                  <WeiXinItem
                    bindingId="_cl_wework_qrcode_online_consulting_nav"
                    fallback="https://wework.qpic.cn/wwpic/828461_UhG2PjvzSNu2_x0_1698631557/0"
                  />
                )}
                {nav.title === '加入社群' && (
                  <WeiXinItem
                    bindingId="_cl_wework_qrcode_join_group_nav"
                    fallback="https://wework.qpic.cn/wwpic/7606_1w6w-2f7TjOjrJk_1698631541/0"
                  />
                )}
                {nav.title === '关注微信' && <WeiXinItem bindingId="_cl_qrcode_official_side" />}
                {nav.title === '电话咨询' && <PhoneCall />}
              </div>
            </div>
          );
        })}
        <div className="nav-btn" key="backtotop" onClick={onBacktoTopClick}>
          <div className="nav-btn-content no-text">
            <div className="nav-btn-icon iconfont font-24 iconbacktotop" />
          </div>
        </div>
      </div>
    </>
  );
}

export default FixedButtons;

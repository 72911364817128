import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Row, Col, Spin, AutoComplete, Checkbox, Select } from 'antd';
import md5 from 'md5';
import CurrentContext from '../context/current';
import getCookie from '../utils/getCookie';
import track from '../utils/track';
import { getDataFromCpm } from '../utils/common';
import ApplyDialogContext from '../context/applyDialog';
import ApplyModalImage from '../images/apply-modal.svg';
import ContactDialog from './ContactDialog';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

const APPLY_FORM_UUID = '7167137e0fdb4568a14f773e814aa1f6';
// const APPLY_FORM_UUID = '4a48dbfe8e1d49ab87715d2ed8e6e79d';
const DOWNLOAD_FORM_UUID = '16c99a8e9ecc46ccb669cec15f4a0490';
const TOOL_FORM_UUID = '63da799fe6354376a3f6d8dae6a67be0';

function validateName(_, value) {
  if (!value || value.trim() === '') {
    return Promise.reject(new Error('请输入姓名'));
  }
  if (value.trim().length < 2) {
    return Promise.reject(new Error('姓名不能少于两个字'));
  }
  if (value.trim().length > 50) {
    return Promise.reject(new Error('姓名太长了'));
  }
  return Promise.resolve();
}

function validateMobile(_, value) {
  if (!value || value.trim() === '') {
    return Promise.reject(new Error('请输入手机号'));
  }
  if (!/^1[0-9]{10}$/.test(value.trim())) {
    return Promise.reject(new Error('请输入正确的手机号格式'));
  }
  return Promise.resolve();
}

function validateStatement(_, value) {
  if (value) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('请阅读并同意《隐私政策声明》'));
}

function VerifyButton({ currentUuid }) {
  useEffect(() => {
    try {
      new cl_captcha({
        hostUrl: 'https://host.huiju.cool',
        resouceType: 'form',
        resourceUuid: currentUuid,
        signature: 'Convertlab',
        codeElement: 'validationCode',
        phoneElement: 'control-hooks_mobile',
        isHost: true,
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Button id="validationCode" type="primary">
      获取验证码
    </Button>
  );
}

VerifyButton.propTypes = {
  currentUuid: PropTypes.string,
};

const queryCache = {};

function ApplyModal(props) {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [utma, setUtma] = useState('');
  const [utmb, setUtmb] = useState('');
  const {
    visible,
    setVisible,
    href,
    setHref,
    modalType,
    setModalType,
    setPageFrom,
    pageFrom,
    fileId,
    productId,
    setProductId,
  } = useContext(ApplyDialogContext);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { current, subCurrent } = useContext(CurrentContext);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const autoTimeout = useRef(null);

  const fields = JSON.parse(formData?.content ?? '{}')?.fields ?? [];

  const getOptionsByField = (field) => {
    const targetField = fields.find((item) => item.name === field);
    return targetField?.options ?? [];
  };
  let currentUuid = APPLY_FORM_UUID;
  if (modalType === 'download') currentUuid = DOWNLOAD_FORM_UUID;
  if ((modalType || '').includes('tool')) currentUuid = TOOL_FORM_UUID;

  const loadFormToken = async () => {
    try {
      const data = await fetch(`https://host.huiju.cool/formdata/get/${currentUuid}`).then((res) =>
        res.json()
      );
      setFormData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const setVisibleAndType = (flag) => {
    setVisible(flag);
    if (!flag) {
      setHref('');
      setPageFrom('');
      setProductId('');
    }
    if (modalType !== 'apply') {
      setTimeout(() => {
        setModalType('apply');
      }, 200);
    }
  };

  useEffect(() => {
    if (visible) {
      loadFormToken();
      const utma1 = getCookie('c__utma');
      const utmb1 = getCookie('c__utmb');
      setUtma(utma1);
      setUtmb(utmb1);
    }
  }, [visible]);

  const onFinish = async (values) => {
    track({ name: '提交申请', current });
    if (!formData) {
      Modal.error({
        title: '服务出错，请刷新页面再重试',
        onOk() {},
      });
      return false;
    }
    setSubmitting(true);

    try {
      const fData = new FormData();
      Object.keys(values).forEach((key) => fData.append(key, values[key] || ''));
      fData.append('mobileVerified', true);
      fData.append('cltoken', formData.token);

      // 从 cpm 获取用户同意信息
      const enabledCustomerCookie = getDataFromCpm('网站必须');
      if (enabledCustomerCookie) {
        fData.append('utma', utma);
        fData.append('utmb', utmb);
      }
      // attr72 - 所需产品类型: cpm 等
      fData.append('attr72', productId || subCurrent);

      const search = typeof window !== `undefined` ? window.location.search : '';

      const response = await fetch(`https://host.huiju.cool/form/${currentUuid}${search}`, {
        method: 'POST',
        body: fData,
      });

      if (!response.ok) {
        const res = await response.text();
        if (res === 'InvalidMobileCode') {
          throw new Error('您输入的验证码有误，请重新获取');
        }
        if (res === 'LimitSubmit') {
          throw new Error('请勿重复提交');
        }
        if (res === 'SubmitFull') {
          throw new Error('提交已达上限');
        }
        throw new Error('服务出错，请稍后再试');
      }

      form.resetFields();
      Modal.success({
        title: '您已成功提交申请，我们将根据您填写的信息与您取得联系',
        onOk: () => {
          // 如果是点击下载链接触发的留资，需要跳转到目标页面
          if (href) {
            if (pageFrom === 'index') {
              window.open(`/whitepaper${search}#${fileId}`);
            } else {
              document.getElementById(fileId).click();
            }
          }
        },
      });
      if (enabledCustomerCookie) {
        // 设置cookie存储该用户的留资情况，供下载事件判断是否需提示用户留资
        const oDate = new Date();
        oDate.setMonth(oDate.getMonth() + 6);
        const uploadedValue = href ? '1' : '2'; // 1 表示是下载资源表单；2 表示是申请试用表单
        document.cookie = `cl_info_uploaded=${uploadedValue};expires=${oDate.toGMTString()};domain=.convertlab.com;path=/`;
      }
      setTimeout(() => {
        setVisibleAndType(false);
      }, 500);
    } catch (err) {
      Modal.error({ title: err.message });
    } finally {
      setSubmitting(false);
    }

    return true;
  };

  const queryCompany = (keyword) => {
    const timestamp = new Date().getTime();
    const token = md5(`${currentUuid}${keyword}${timestamp}`);
    const data = {
      keyword,
      timestamp,
      token,
    };

    fetch(`https://host.huiju.cool/qixin/advSearch/${currentUuid}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.rows && res.rows.length) {
          const newItems = res.rows.map((item) => ({ label: item.name, value: item.name }));
          queryCache[keyword] = newItems;
          setItems(newItems);
          setLoading(false);
        }
      });
  };

  const onChangeAutoComplete = (value) => {
    setLoading(true);
    form.setFieldsValue({ company: value });
    const v = value
      .trim()
      .replace(
        /[ |~|`|!|@|#|$|%|^|&|*|(|)|\-|_|+|=|||\\|[|\]|{|}|;|:|"|'|,|<|.|>|/|?|。|，|、|？|；|‘|“|：|！|（|）|——|·|「|」|『|』]/g,
        ''
      );

    if (autoTimeout.current) {
      clearTimeout(autoTimeout.current);
    }
    if (v.length < 2) {
      setLoading(false);
      setItems([]);
    } else {
      if (queryCache[v]) {
        setItems(queryCache[v]);
        setLoading(false);
        return;
      }
      autoTimeout.current = setTimeout(() => queryCompany(v), 600);
    }
  };

  let title = '申请试用';
  let subTitle = '数智赋能每一个岗位';
  if (modalType === 'download') {
    title = '下载白皮书';
    subTitle = '完善信息后立即获得白皮书';
  }
  if ((modalType || '').includes('tool')) {
    title = '获取属于你的营销主题';
    subTitle = '完善信息后立即获得全部营销主题';
  }

  // eslint-disable-next-line no-shadow
  const VCodeFormContent = (props) => {
    const { value, onChange } = props;
    return (
      <div className="code-wrapper-form-item">
        <Input
          placeholder="请填写验证码"
          maxLength={6}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <VerifyButton currentUuid={currentUuid} />
      </div>
    );
  };
  VCodeFormContent.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
  };
  return (
    <>
      <Modal
        {...props}
        // forceRender
        visible={visible}
        onCancel={() => {
          setVisibleAndType(false);
          setContactModalVisible(true);
        }}
        maskStyle={{ background: 'rgba(0,0,0,.8)' }}
        className="apply-dialog"
        getContainer="#___gatsby"
        width={1100}
        // zIndex={9999}
        footer={null}
      >
        <Spin spinning={submitting}>
          <h3 className="apply-title">{title}</h3>
          <h5 className="apply-sub-title">{subTitle}</h5>
          <Row className="modal-content-wrapper">
            <Col span={9} className="modal-image">
              <img src={ApplyModalImage} alt="modal" />
            </Col>
            <Col lg={15} md={24} className="form-wrapper">
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                data-cl-attached="false"
                data-cl-id={currentUuid}
                labelAlign="right"
                colon={false}
              >
                <Form.Item name="name" rules={[{ validator: validateName }]} label="姓名" required>
                  <Input placeholder="请填写姓名" maxLength={50} />
                </Form.Item>
                <Form.Item
                  name="mobile"
                  rules={[{ validator: validateMobile }]}
                  label="手机号码"
                  required
                >
                  <Input placeholder="请填写手机号码" maxLength={11} />
                </Form.Item>
                <Form.Item
                  name="code"
                  rules={[{ required: true, message: '请填写验证码' }]}
                  label="验证码"
                  required
                >
                  <VCodeFormContent />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: '请填写邮箱地址' },
                    { type: 'email', message: '邮箱地址填写有误' },
                  ]}
                  label="邮箱地址"
                  required
                >
                  <Input placeholder="请填写邮箱地址,否则无法开通试用账号" />
                </Form.Item>
                <Form.Item
                  label="公司名称"
                  name="company"
                  rules={[{ required: true, message: '请填写公司名称' }]}
                  required
                >
                  <Select
                    placeholder="仅支持选择公司全称，可输入关键字检索"
                    options={items}
                    onSearch={onChangeAutoComplete}
                    showSearch
                    loading={loading}
                  />
                </Form.Item>
                <Form.Item
                  label="公司简称"
                  required
                  name="attr21"
                  rules={[
                    { required: true, message: '请填写公司简称或产品名称，如我司简称Convertlab' },
                  ]}
                >
                  <Input placeholder="请填写公司简称或产品名称，如我司简称Convertlab" />
                </Form.Item>
                <Form.Item
                  label="所属行业"
                  name="attr1"
                  rules={[{ required: true, message: '请选择所属行业' }]}
                >
                  <Select options={getOptionsByField('attr1')} placeholder="请选择所属行业" />
                </Form.Item>
                <Form.Item
                  label="部门"
                  name="attr15"
                  rules={[{ required: true, message: '请选择部门' }]}
                >
                  <Select options={getOptionsByField('attr15')} placeholder="请选择部门" />
                </Form.Item>
                <Form.Item
                  label="职级"
                  name="attr16"
                  rules={[{ required: true, message: '请选择职级' }]}
                >
                  <Select options={getOptionsByField('attr16')} placeholder="请选择职级" />
                </Form.Item>
                <Form.Item
                  label=" "
                  name="privacyStatement"
                  valuePropName="checked"
                  rules={[{ validator: validateStatement }]}
                >
                  <Checkbox>
                    我已阅读并同意
                    <a href="/privacy" target="_blank">
                      《Convertlab隐私政策》
                    </a>
                    和
                    <a href="/childrenprivacy" target="_blank">
                      《儿童隐私保护声明》
                    </a>
                  </Checkbox>
                </Form.Item>
                <Form.Item className="button-row" label=" ">
                  <Button type="primary" htmlType="submit" block>
                    提交
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
      <ContactDialog
        visible={contactModalVisible}
        onCancel={() => {
          setContactModalVisible(false);
        }}
      />
    </>
  );
}

export default ApplyModal;

import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import ApplyButton from './ApplyButton';

function Apply({ title = '', display = 'block' }) {
  return (
    <section id="apply" className={`apply-section ${display}`}>
      <Container fluid="xl">
        <div className="left-top-vein" />
        <h3 className="title">{title}</h3>
        <div className="action-area">
          <ApplyButton size="large" className="green" buttonPosition="bottom">
            申请试用
          </ApplyButton>
        </div>
      </Container>
    </section>
  );
}

Apply.propTypes = {
  title: PropTypes.string,
  display: PropTypes.oneOf(['block', 'inline']),
};

export default Apply;

import React, { useContext, useEffect, useState } from 'react';
import { Menu, Button } from 'antd';
import PropTypes from 'prop-types';
import ApplyButton from './ApplyButton';
import CurrentContext from '../context/current';
import { MENUS } from '../constants';
import { useSearch, onMenuSelect } from '../utils/common';
import LogoImage from '../images/logo-211201.svg';
import Bars from '../images/bars.svg';

const { SubMenu } = Menu;

const BREAKPOINT = 991;
function MenuBar({
  customData,
  className,
  logo = LogoImage,
  applyContent = '申请试用',
  hideApply,
  mobilButton,
}) {
  const { subCurrent } = useContext(CurrentContext);

  const [currentPathKey, setCurrentPathKey] = useState(subCurrent);
  const [currentMenuMode, setMenuMode] = useState('horizontal');
  const [currentOpenKeys, setCurrentOpenKeys] = useState([]);
  const [menuVisible, setMenuVisible] = useState(false);

  const search = useSearch();
  // 屏宽 < 992 时使用纵列目录
  function onWindowResize() {
    if (window.innerWidth <= BREAKPOINT && currentMenuMode === 'horizontal') {
      setMenuMode('inline');
    }
    if (window.innerWidth > BREAKPOINT && currentMenuMode === 'inline') {
      setMenuMode('horizontal');
    }
  }

  useEffect(() => {
    setMenuMode(window.innerWidth <= BREAKPOINT ? 'inline' : 'horizontal');
    const { pathname, hash } = window.location;
    if (hash === '#contacts' && pathname.includes('/about')) {
      setCurrentPathKey('about/#contacts');
    }
    return () => {
      const bodyEle = document.querySelector('body');
      bodyEle.style.height = 'auto';
      bodyEle.style.overflow = 'scroll-y';
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      document.removeEventListener('resize', onWindowResize);
    };
  }, [currentMenuMode]);

  // 小屏幕时：切换菜单显隐的按钮事件
  function onToggleClick() {
    setMenuVisible(!menuVisible);
    const bodyEle = document.querySelector('body');
    if (menuVisible) {
      bodyEle.style.height = 'auto';
      bodyEle.style.overflowX = 'scroll-y';
    } else {
      bodyEle.style.height = '100%';
      bodyEle.style.overflowX = 'hidden';
    }
  }

  // 切换菜单的事件
  function onOpenChange(openKeys = []) {
    setCurrentOpenKeys(openKeys.length ? [openKeys[openKeys.length - 1]] : []);
  }

  // hash 点击
  function onClickHash(event, id) {
    event.preventDefault();
    event.stopPropagation();
    if (window && id && document.getElementById(`${id}`)) {
      document.getElementById(`${id}`).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }

  return (
    <div>
      <div id="nav-bar" className={`nav-bar ${className}`}>
        <div className="container-xl">
          <div className="nav-content">
            <div className="mobile-nav-wrapper">
              <div className="logo-wrapper">
                <a href={`/${search}`} className="logo">
                  <img src={logo} className="brand" alt="convertlab" />
                </a>
                <a
                  href="//en.convertlab.com"
                  className="en-button"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  EN
                </a>
              </div>
              {mobilButton}
              <div className="mobile-buttons">
                <div className="mobile-apply-container">
                  <ApplyButton phone buttonPosition="menu">
                    申请试用
                  </ApplyButton>
                </div>
                <Button className="toggle" onClick={onToggleClick}>
                  <img src={Bars} alt="icon" />
                </Button>
              </div>
            </div>
            <div className={`menu-wrapper ${menuVisible ? 'show' : 'hide'}`}>
              <Menu
                selectedKeys={[currentPathKey]}
                openKeys={currentOpenKeys}
                onOpenChange={onOpenChange}
                mode={currentMenuMode}
                triggerSubMenuAction="hover"
                // triggerSubMenuAction="click"
                onClick={(props) => onMenuSelect(props, onToggleClick)}
                getPopupContainer={() => document.querySelector('.menu-wrapper')}
              >
                {(customData || MENUS).map((e) => {
                  const { showIcon } = e;
                  if (e.children) {
                    return (
                      <SubMenu
                        key={e.id}
                        popupOffset={[-24, 7]}
                        title={<span>{e.name}</span>}
                        className={e.id === currentPathKey ? 'active' : ''}
                      >
                        {e.children.map((child) => {
                          let url = `${child.id}/`;
                          if (!child.omitSource && (search || child.id.includes('#'))) {
                            url = `${child.id}${search}`;
                          } else if (child.href) {
                            url = child.href;
                          }
                          const iconRender = showIcon && child.iconId && (
                            <span className={`menu-icon ${child.iconId}`} />
                          );
                          if (child.noPage) {
                            return (
                              <Menu.Item className={child.type} key={child.id}>
                                {iconRender}
                                {child.name}
                              </Menu.Item>
                            );
                          }
                          return (
                            <Menu.Item
                              className={child.type}
                              key={(child.id || '').replace('/', '')}
                            >
                              <a href={url} target={child.target} rel={child.rel}>
                                {iconRender}
                                {child.name}
                              </a>
                              {child.type === 'parent' && <div className="menu-parent-divider" />}
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={e.isHash ? e.id || 'summits-home' : e.id}>
                      {e.isHash ? (
                        <a href={`/#${e.id}`} onClick={(event) => onClickHash(event, e.id)}>
                          {e.name}
                        </a>
                      ) : (
                        <a href={`${e.id === 'home' ? '/' : `/${e.id}/`}${search}`}>
                          {e.nameImage || e.name}
                        </a>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu>
              {!hideApply && (
                <div className="menu-apply-button-container">
                  <ApplyButton phone buttonPosition="menu">
                    {applyContent}
                  </ApplyButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MenuBar.propTypes = {
  customData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isHash: PropTypes.bool,
    })
  ),
  className: PropTypes.string,
  logo: PropTypes.string,
  applyContent: PropTypes.string,
  hideApply: PropTypes.bool,
  mobilButton: PropTypes.node,
};

export default MenuBar;

import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { loadQrcodeQw } from '../utils/common';

function ContactDialog(props) {
  useEffect(() => {
    loadQrcodeQw('onlineConsulting1');
  });
  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.onCancel()}
      footer={null}
      className="contact-dialog"
      width={800}
      maskStyle={{ background: 'rgba(0,0,0,.8)' }}
    >
      <div className="text-content">
        <h3 className="contact-title">让营销变得简单，让增长自然而然</h3>
        <div className="qrcode-container" id="_cl_wework_qrcode_online_consulting_form">
          <img src="https://wework.qpic.cn/wwpic/234593_L08vocRsTC6mNMQ_1698630650/0" alt="" />
        </div>
        <div className="conact-subtitle">
          <p>扫码添加企业微信</p>
          <p>获取量身定制的营销解决方案</p>
        </div>
      </div>
    </Modal>
  );
}

export default ContactDialog;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Menu } from 'antd';
import Container from './Container';
import Copyright from './Copyright';
import FriendlyLinks from './FriendlyLinks';
import { onMenuSelect, useSearch } from '../utils/common';
import LogoWhite from '../images/logo-211201-white.svg';
import FollowUs from '../images/qrcode/official_footer.jpg';

const QrItem = ({ icon, text, bindingId, fallback }) => {
  return (
    <div className="qrCode-item">
      {bindingId ? (
        <div className="binding-qrcode" id={bindingId}>
          <img src={FollowUs || fallback} className="qrCode" alt="QR Code" />
        </div>
      ) : (
        <img src={icon} className="qrCode" alt="QR Code" />
      )}
      <div className="title">{text}</div>
    </div>
  );
};

QrItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  bindingId: PropTypes.string,
  fallback: PropTypes.string,
};

const FOOTER_DATA = [
  {
    title: '产品矩阵',
    key: 'products',
    list: [
      {
        key: 'marketingcloud',
        subTitle: 'Convertlab 营销云',
        href: '/marketingcloud/',
        type: 'parent',
      },
      {
        key: 'dmhub',
        subTitle: 'DM Hub 营销自动化平台',
        href: '/dmhub/',
      },
      {
        key: 'cdp',
        subTitle: 'Data Hub 客户数据平台',
        href: '/cdp/',
      },
      {
        key: 'adhub',
        subTitle: 'Ad Hub 智能广告平台',
        href: '/adhub/',
      },
      {
        key: 'pechub',
        subTitle: 'PEC 隐私计算平台',
        href: '/pechub',
      },
      {
        key: 'aihub',
        subTitle: 'AI Hub 数据智能引擎',
        href: '/aihub/',
      },
      {
        key: 'openhub',
        subTitle: 'Open Hub 开放平台',
        href: '/openhub/',
      },
      {
        key: 'huiju',
        href: 'https://www.huiju.cool/',
        subTitle: 'B2B荟聚数字营销云',
        target: '_blank',
        rel: 'noreferrer noopener',
      },
    ],
  },
  {
    title: '应用场景',
    key: 'scen',
    list: [
      {
        key: 'digitalmarketing',
        subTitle: '数字化营销',
        href: '/digitalmarketing/',
      },
      {
        key: 'royalty',
        subTitle: '会员营销',
        href: '/royalty/',
      },
      {
        key: 'scrm',
        subTitle: 'SCRM',
        href: '/scrm/',
      },
      // {
      //   key: '2-4',
      //   subTitle: '企业微信营销',
      //   href: '',
      // },
      {
        key: 'campaign',
        subTitle: '营销活动管理',
        href: '/campaign/',
      },
      {
        key: 'privatetraffic',
        subTitle: '私域流量运营',
        href: '/privatetraffic/',
      },
      {
        key: 'personas',
        subTitle: '用户画像',
        href: '/personas/',
      },
      {
        key: 'tags',
        subTitle: '标签体系',
        href: '/tags/',
      },
    ],
  },
  {
    title: '行业案例',
    key: 'cases',
    list: [
      {
        key: 'brandretail',
        subTitle: '品牌零售',
        href: '/brandretail/',
      },
      {
        key: 'modernretail',
        subTitle: '现代零售',
        href: '/modernretail/',
      },
      // {
      //   key: '3-3',
      //   subTitle: '商业综合体',
      //   href: '',
      // },
      // {
      //   key: '3-4',
      //   subTitle: '电子商务',
      //   href: '',
      // },
      {
        key: 'fmcg',
        subTitle: '快消行业',
        href: '/fmcg/',
      },
      {
        key: 'durables',
        subTitle: '耐消行业',
        href: '/durables/',
      },
      {
        key: 'financial',
        subTitle: '金融行业',
        href: '/financial/',
      },
      {
        key: '3-8',
        subTitle: 'B2B行业',
        href: '/b2b/',
      },
      // {
      //   key: '3-9',
      //   subTitle: '其他行业',
      //   href: '',
      // },
    ],
  },
  {
    title: '工具与资讯',
    key: 'sources',
    list: [
      {
        key: '4-2',
        subTitle: 'MarTech Wiki',
        href: 'http://martechwiki.convertlab.com/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
      },
      // {
      //   key: '4-4',
      //   subTitle: '活动',
      //   href: '',
      // },
      {
        key: '4-5',
        subTitle: 'API文档',
        href: 'https://api-docs.convertlab.com/v2/restapi/overview',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
      },
      // {
      //   key: 'openhub',
      //   subTitle: '开放平台',
      //   href: '/openhub/',
      // },
      {
        key: '4-7',
        subTitle: '产品更新',
        href: 'https://www.convertlab.com/updateInfo',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
      },
      {
        key: 'yourtitles',
        subTitle: '智能营销主题生成器',
        href: '/yourtitles/',
      },
      {
        key: 'blogs',
        subTitle: '洞察 | MarTech增长笔记',
        href: '/blogs/',
      },
      {
        key: '4-cc',
        subTitle: '视频资源 | CC有料',
        href: '/ccmeaningful/',
      },
      {
        key: 'whitepaper',
        subTitle: '白皮书下载',
        href: '/whitepaper/',
      },
      {
        key: 'lifeCycle',
        subTitle: '产品生命周期说明',
        target: '_blank',
        href: 'https://host.convertlab.com/page/1238467299/7d3fa761f91544d6a1ccccacda18209e',
      },
    ],
  },
  {
    title: '我们',
    key: 'aboutus',
    list: [
      {
        key: 'about',
        subTitle: '关于我们',
        href: '/about/',
      },
      // {
      //   key: '5-2',
      //   subTitle: '团队',
      //   href: '',
      // },
      {
        key: 'about/#contacts',
        subTitle: '联系我们',
        href: '/about/#contacts',
      },
      {
        key: '5-4',
        subTitle: '加入我们',
        href: 'https://app.mokahr.com/social-recruitment/convertlab/54112',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
      },
      {
        key: 'partner',
        subTitle: '合作伙伴',
        href: '/partner/',
      },
      {
        key: 'seo-news',
        subTitle: '行业洞察',
        href: '/industryinsight/',
      },
    ],
  },
];

const BREAKPOINT = 991;
const DEFAULT_OPEN_KEYS = FOOTER_DATA.map((item) => item.key);

export default function Footer({ current }) {
  const [currentMenuMode, setMenuMode] = useState('inline');
  const [openKeys, setOpenKeys] = useState(DEFAULT_OPEN_KEYS);
  const search = useSearch();

  // 屏宽 < 992 时使用纵列目录
  function onWindowResize() {
    if (window.innerWidth <= BREAKPOINT && currentMenuMode === 'inline') {
      setMenuMode('vertical');
      setOpenKeys([]);
    }
    if (window.innerWidth > BREAKPOINT && currentMenuMode === 'vertical') {
      setMenuMode('inline');
      setOpenKeys(DEFAULT_OPEN_KEYS);
    }
  }

  useEffect(() => {
    setMenuMode(window.innerWidth <= BREAKPOINT ? 'vertical' : 'inline');
    setOpenKeys(window.innerWidth <= BREAKPOINT ? [] : DEFAULT_OPEN_KEYS);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      document.removeEventListener('resize', onWindowResize);
    };
  }, [currentMenuMode]);

  // 目录点击事件
  function onMenuClick(e) {
    if (currentMenuMode === 'inline') return;
    setOpenKeys(e);
  }

  return (
    <section id="footer" className="footer">
      <Container fluid="xl">
        <Row>
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
            className="logo-qrCode-wrapper"
          >
            <div className="logo-and-contact">
              <img src={LogoWhite} className="logo" alt="convertlab" />
              <div className="contact-item">
                <a href="tel://4008509918">咨询电话：400-850-9918</a>
              </div>
              <div className="contact-item">
                <a href="mailto:info@convertlab.com">邮箱：info@convertlab.com</a>
              </div>
            </div>
            <div className="qr-code-wrapper">
              <QrItem bindingId="_cl_qrcode_official_footer" text="关注公众号" />
              <QrItem
                bindingId="_cl_wework_qrcode_join_group_footer"
                fallback="https://wework.qpic.cn/wwpic/250716_FWNGorbeRfyjWv6_1698631653/0"
                text="加入营销社群"
              />
            </div>
          </Col>
          <Col
            lg={{ span: 18, order: 2 }}
            md={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            xs={{ span: 24, order: 1 }}
            className={currentMenuMode}
          >
            <Menu
              className="footer-menu-wrapper"
              mode="inline"
              onOpenChange={onMenuClick}
              openKeys={openKeys}
              selectable={false}
              triggerSubMenuAction="click"
              onClick={onMenuSelect}
            >
              {FOOTER_DATA.map((footerItem) => {
                return (
                  <Menu.SubMenu
                    className="footer-menu"
                    key={footerItem.key}
                    title={
                      <div className="menu-title-content">
                        {footerItem.title}
                        <div className="divider" />
                      </div>
                    }
                  >
                    {footerItem.list.map((subItem) => {
                      let content;
                      if (subItem.href) {
                        content = (
                          <a href={subItem.href + search} target={subItem.target} rel={subItem.rel}>
                            {subItem.subTitle}
                          </a>
                        );
                      } else {
                        content = subItem.subTitle;
                      }
                      return <Menu.Item key={subItem.key}>{content}</Menu.Item>;
                    })}
                  </Menu.SubMenu>
                );
              })}
            </Menu>
          </Col>
        </Row>
        {current === 'home' && <FriendlyLinks />}
        <Copyright />
      </Container>
    </section>
  );
}

import React from 'react';
import Container from './Container';

// 022 001已删除
const FRIENDLIST = [
  { title: 'WEB前端培训', href: 'https://www.sixstaredu.com/ ', id: '002' },
  { title: '会员管理系统', href: 'http://www.ypxx01.com/', id: '003' },
  { title: '数据恢复软件', href: 'https://www.easeus.com.cn/', id: '004' },
  { title: '餐饮收银系统', href: 'https://www.tcsl.com.cn/', id: '006' },
  { title: '电话呼叫系统', href: 'https://www.holly400.com/', id: '007' },
  { title: '企业管理软件', href: 'http://www.8manage.cn/', id: '008' },
  { title: '文档加密', href: 'http://www.wonderonline.cn/', id: '009' },
  { title: '收银系统', href: 'https://www.td365.com.cn/', id: '010' },
  { title: '光伏监控系统', href: 'https://www.growatt.com/', id: '011' },
  { title: '远程工作', href: 'https://www.yungong.com/', id: '012' },
  { title: '微信商城系统', href: 'http://www.hnzylc.cn/', id: '013' },
  { title: '云服务器', href: 'https://www.changxingyun.com/', id: '014' },
  { title: '工程项目管理软件', href: 'https://www.hecom.cn/', id: '015' },
  { title: 'app开发', href: 'https://www.kubikeji.com/', id: '016' },
  { title: 'OA系统', href: 'http://www.10oa.com/', id: '017' },
  { title: 'HR系统', href: 'http://www.tongxine.com/', id: '018' },
  { title: '生鲜配送系统', href: 'http://www.niaocms.com/', id: '019' },
  { title: '校园营销', href: 'https://www.xiaoguokeji.com/', id: '020' },
  { title: 'crm系统', href: 'http://www.teamface.cn/', id: '021' },
  { title: '短视频源码', href: 'http://www.yunbaokj.com/', id: '023' },
  { title: '代运营', href: 'http://www.1shuadan.com/', id: '024' },
  { title: 'ERP', href: 'http://www.centersoft.com.cn/', id: '025' },
  { title: '短链接', href: 'http://ehuoma.com/', id: '026' },
  { title: '电商平台', href: 'http://www.shxuanming.net/', id: '027' },
  { title: 'oa系统', href: 'http://www.jwzkj.com/', id: '028' },
  { title: '迎宾机器人', href: 'http://www.pudutech.com/', id: '029' },
];

export default function FriendlyLinks() {
  return (
    <div id="friendly-links" className="friendly-links">
      <Container>
        <div className="content">
          {FRIENDLIST.map((item) => (
            <div className="item" key={item.id}>
              <a href={item.href} target="_blank" rel="noreferrer noopener">
                {item.title}
              </a>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
